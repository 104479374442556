<template>
  <div id="app">
    <MainNavbar />
    <router-view />
    <MainFooter />
  </div>
</template>

<script>
import MainNavbar from "@/components/common/MainNavbar";
import MainFooter from "@/components/common/MainFooter";

export default {
  components: {
    MainNavbar,
    MainFooter,
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "Gotham";
  src: local("Gotham");
  src: url("~@/assets/fonts/Gotham/Gotham-Light.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "Gotham";
  src: local("Gotham");
  src: url("~@/assets/fonts/Gotham/Gotham-Book.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Gotham";
  src: local("Gotham");
  src: url("~@/assets/fonts/Gotham/Gotham-Medium.otf") format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "Gotham";
  src: local("Gotham");
  src: url("~@/assets/fonts/Gotham/Gotham-Bold.otf") format("opentype");
  font-weight: 700;
}

#app {
  font-family: "Gotham", sans-serif;
  text-align: center;
  color: #2c3e50;
}

.is-default-width {
  width: 128px;
}
</style>
