<template>
  <b-navbar id="MainNavbar" fixed-top shadow>
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img src="@/assets/nixt_logo.svg" width="48px" />
      </b-navbar-item>
    </template>

    <template #end>
      <b-navbar-item href="https://care.nixt.banpunext.app" target="_blank">
        NiXT Care
      </b-navbar-item>
      <b-navbar-item href="https://doc.nixt.banpunext.app" target="_blank">
        Docs
      </b-navbar-item>
      <b-navbar-item tag="div">
        <b-button
          type="is-primary"
          size="is-small"
          rounded
          tag="router-link"
          :to="{ name: 'get-start' }"
        >
          GET STARTED
        </b-button>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
export default {
  name: "MainNavbar",
};
</script>

<style scoped></style>
