import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    project_type: null,
    manday_list: [],
  },
  mutations: {
    SET_PROJECT_TYPE(state, payload) {
      state.project_type = payload;
    },
    SET_MANDAY_LIST(state, payload) {
      state.manday_list = payload;
    },
  },
});
