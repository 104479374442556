<template>
  <footer id="MainFooter" class="footer">
    <div class="content has-text-centered">
      <p>
        <strong style="color: #6c6c6c">NiXT Platform</strong> <br />
        © Copyright 2021 Banpu NEXT Co., Ltd.
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "MainFooter",
};
</script>

<style scoped></style>
